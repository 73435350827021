import React from 'react'
import './FoodCard.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
const Food = ({ name, price, src }) => {
  return (
    <div className="page-container">
      <div className="outer">
        <div className="inner" style={{ backgroundImage: `url(${src})` }}></div>
        <div className="item">
          <div className="item-name">
            <h4>{name}</h4>
            <p>Yüksek Kalite!</p>
          </div>
          <div className="item-price">
            <p>₺{price},00</p>
            {
              
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default Food
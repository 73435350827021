import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scroll.css';
import './install.css';
import { Navbar, Container , Nav, Card, Modal, Button, Form  } from 'react-bootstrap'
import {IoEllipsisVerticalOutline} from 'react-icons/io5';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Food from './components/Food';
import api from './api/axiosConfig';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import hamburger from './components/tost.png';
import LogoTcdd from './components/logo.png';
import LogoYdc from './components/ydclogo.png';
import ShareIcon from './components/share.png';
import LogoTrain from './components/train.png';
import LogoDijital from './components/dijital2.jpeg';
import initialModalImage from './components/kampanya.jpeg';
import Notification from './components/Notification';



function App() {

  const [showFormModal, setShowFormModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(true);
  const handleCloseCampaignModal = () => setShowCampaignModal(false);
  const [showIosInstallMessage, setShowIosInstallMessage] = useState(false);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    console.log("User Agent:", userAgent); 
    return /iphone|ipad|ipod/.test(userAgent);
  };
  const isInStandaloneMode = () => {
    const isStandalone = ('standalone' in window.navigator) && window.navigator.standalone;
    console.log("Is in Standalone Mode:", isStandalone); 
    return isStandalone;
  };

  useEffect(() => {
    if (isIos() && !isInStandaloneMode()) {
      console.log("Showing iOS Install Message"); 
      setShowIosInstallMessage(true);
    } else {
      console.log("Not showing iOS Install Message");
    }
  }, []);

  const [foods, setFoods] = useState([]);
  useEffect(()=>{

    async function getFoods() {
        try {
            const res = await api.get("/food/listall")
            console.log(res.data);
            setFoods(res.data);
          }
          
          catch(err){
            console.log(err);
          }
      }
      getFoods();

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('./sw.js')
          .then(function(registration) {
            console.log('Service Worker Registered', registration);
          })
          .catch(function(error) {
            console.error('Service Worker Registration Failed:', error);
          });  
      }
  
    },[])
    const f = Object.groupBy(foods, ({category}) => category);
    console.log(f);
    const reklam = f.Reklamlar;
    console.log(reklam);
    
    const handleCloseForm = () => setShowFormModal(false);
  
    const handleShowForm = () => setShowFormModal(true);

    const handleSuccessClose = () => setShowSuccessModal(false);
  
   // Yeni eklenen state'ler ve handler fonksiyonları
  const [wagonNumber, setWagonNumber] = useState('');
  const [seatNumber, setSeatNumber] = useState('');

  const handleWagonNumberChange = (e) => setWagonNumber(e.target.value);
  const handleSeatNumberChange = (e) => setSeatNumber(e.target.value);

  const handleSendWaiterCall = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + 'waiter-calls', {
        wagon_number: wagonNumber,
        seat_number: seatNumber
      });

      if (response.status === 200) {
        handleCloseForm();
        setShowSuccessModal(true);
        setTimeout(() => {
          handleSuccessClose();
        }, 1000);
      }
    } catch (error) {
      console.error("Error sending waiter call:", error);
    }
  };


  return (
    <>
        {showIosInstallMessage && (
         <div className="install-prompt">
           <p>Kampanyalardan Haberdar Olmak için: Tıklayın <img src={ShareIcon} alt="Share Icon" style={{ width: 24, height: 24, verticalAlign: 'middle' }} /> ve 'Ana Ekrana Ekleyin'.</p>
        </div>
      )}
    <Navbar fixed="top"  expand="lg" bg ="primary" data-bs-theme="dark">
      <Navbar.Toggle aria-controls="responsive-navbar-nav"  >
        <IoEllipsisVerticalOutline size = "30" style={{color : "white"}}/>
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" style = {{alignItems: "center"}}>
          
          {Object.keys(f).map(groupName => (
            
            (groupName != "Reklamlar") && <Nav.Link href={`#${groupName}`}>{groupName}</Nav.Link>
          ))}
          
        </Nav>
      </Navbar.Collapse>
    </Navbar>
           
    <div style={{marginTop: 100}}>
      <Container >  

      <Modal show={showCampaignModal} onHide={handleCloseCampaignModal}>
            <Modal.Header closeButton>
              <Modal.Title>Bugünün Kampanyası</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={initialModalImage} alt="Kampanya Resmi" style={{ width: '100%' }} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseCampaignModal}>
                Kapat
              </Button>
            </Modal.Footer>
          </Modal>

      <div style={{textAlign: "center", marginBottom: 20}}>
          <img
            src={LogoTcdd}
            height="100"
            className="d-inline-block align-top"
            alt="Logo"
          />
             <img
            src={LogoYdc}
            height="90"
            className="d-inline-block align-top"
            alt="Logo"
            style={{ marginTop: '19px' }}
          />
             <Card style={{
  width: '20rem', 
  margin: '20px auto', 
  borderRadius: '15px', 
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
  border: 'none',
  overflow: 'hidden'
}}>
  <Card.Body style={{
    backgroundColor: '#f8f9fa',
    padding: '20px'
  }}>
    <Card.Text style={{
      color: '#495057', 
      fontSize: '16px', 
      fontFamily: 'Arial, sans-serif', 
      lineHeight: '1.5', 
      textAlign: 'left'
    }}>
      YDC Grup olarak, sizlere her zaman en yüksek kalitede hizmet sunmayı hedefliyoruz.
      Lezzetli yemeklerimizle yolculuğunuz boyunca keyifli anlar yaşayın.
    </Card.Text>
  </Card.Body>
</Card>
   
  <Button variant="primary" onClick={handleShowForm} style={{ marginTop: '10px' }} className="btn-lg">
              Garson Çağır
            </Button>
            <Modal show={showFormModal} onHide={handleCloseForm}>
              <Modal.Header closeButton>
                <Modal.Title>Garson Çağır</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">Vagon Numarası</Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Vagon numarası"
                        value={wagonNumber}
                        onChange={handleWagonNumberChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">Koltuk Numarası</Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Koltuk numarası"
                        value={seatNumber}
                        onChange={handleSeatNumberChange}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleCloseForm}>Kapat</Button>
                <Button variant="primary" onClick={handleSendWaiterCall}>Gönder</Button>
              </Modal.Footer>
            </Modal>


      <Modal show={showSuccessModal} onHide={handleSuccessClose}>
        <Modal.Header closeButton>
          <Modal.Title>Talebiniz Alınmıştır</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Garson çağrı talebiniz alınmıştır.<br></br> 5 dakika içerisinde siparişiniz alınacaktır.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessClose}>Kapat</Button>
        </Modal.Footer>
      </Modal>
<img
            src={LogoTrain}
            height="51"
            className="d-inline-block align-top"
            alt="Logo"
            style={{ marginTop: '19px', alignItems: 'left' }}
          />
          <br></br>
          <br></br>
          <br></br>
          <img
            src={LogoDijital}
            height="90"
            className="d-inline-block align-top"
            alt="Logo"
            style={{ marginTop: '19px', alignItems: 'left' }}
          />
          <br></br>
          <br></br>
           <span id="scroll-title">
    Menü için Kaydırınız
  </span>
          <div id="scroll-down">
  <span class="arrow-down">
  </span>
 
</div>
        </div>
      <Notification/>        
        {Object.entries(f).map(([groupName, group], index) => (
          <div>
            {(groupName != "Reklamlar") && <div id={groupName}><hr/><h1 style={{textAlign : "center", marginTop: 50}}>{groupName}</h1><hr/></div>}
              <div>
                <Row className="justify-content-md-evenly justify-content-center" >

                  {group.map((item, index) => (
                    <Col xs ="auto"  md="auto">
                      {(item.category != "Reklamlar") &&
                      <Food 
                      name= {item.name} 
                      price = {item.price} 
                      src = {`${process.env.REACT_APP_BACKEND_URL}images/${item.image}`}
                      />}
                    </Col>
                  ))}

                </Row>
             </div>
             {((index < reklam.length) && <div style={{textAlign : "center", marginTop: 50}}><a href = {reklam[index].name}><img src = {`${process.env.REACT_APP_BACKEND_URL}images/${reklam[index].image}`} style={{width : '60%', height :"50%"}}/></a></div>)}
                 
           </div>
                   ))}
      </Container >
    </div>
    
    </>
  );
}

export default App;
